.progress-ring {
  position: relative;
  width: 40px;
  height: 40px;
}

.progress-ring__circle,
.progress-ring__progress {
  stroke-dasharray: 113.097; /* 2 * PI * 18 */
  stroke-dashoffset: 113.097;
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.progress-ring__progress {
  stroke-dashoffset: 0;
}

.progress-ring button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: none;
  border: none;
  color: black;
  font-size: 14px;
  cursor: pointer;
}