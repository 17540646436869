:root {
  --scroll-bar-width: 8px;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(247, 242, 242);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: var(--scroll-bar-width);
  height: 16px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  /* background: #bbbbbb; */
  background: rgba(187, 187, 187, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(187, 187, 187, 0.4);
  border-radius: 10px;
}

/**
  * Overwrite react-grid-layout shadow of draggable item
  */

.react-grid-item.react-grid-placeholder {
  border-radius: 32px;
  background: rgba(0, 0, 0);
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

/**
 * Overwrite react-grid-layout transition styles
 */

.react-grid-item {
  transition: all 375ms ease-in, opacity 375ms ease-in;
}

/**
 * Navbar highlight effect
 */

.transition_deactivate {
  transition: none;
}

.transition_activate {
  transition: left 0.375s ease-in, width 0.375s ease-in;
}

/**
 * Navbar focus effect
 */

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/**
  * Apple iOS app rumble effect
  */

@keyframes rumble {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-2.25deg);
  }
  75% {
    transform: rotate(2.25deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes earthquake {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-1deg);
  }
  75% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* Bogart Fonts */

@font-face {
  font-family: "Bogart";
  src: url("../public/fonts/bogart/Bogart-Semibold-trial.ttf")
    format("truetype");
}

@font-face {
  font-family: "Bogart-Light-Italic";
  src: url("../public/fonts/bogart/Bogart-Light-Italic-trial.ttf")
    format("truetype");
}


/* Tooltip */

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 69px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 102%; /* Place the tooltip above the text */
  left: 55%;
  margin-left: -60px; /* Use half of the width to center the tooltip */

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%; /* Below the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}